import classnames from 'classnames';
import identity from 'lodash/identity';
import maxBy from 'lodash/maxBy';
import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {connect} from 'react-redux';

import {gettext} from 'airborne/gettext';
import {copyCarOffer} from 'airborne/search2/helpers/copyToClipboard';
import CopyToClipboardTooltip from 'airborne/search2/hotel/CopyToClipboardTooltip';
import {getPaymentLabel} from 'airborne/cars/search/helpers/paymentOptions';
import {getRatePlanI18N} from 'airborne/cars/search/types';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {format as formatPrice} from 'airborne/utils/price';


import BookButton from './BookButton';
import CarInfo from './CarInfo';
import MileageInfo from './MileageInfo';
import VendorInfo from './VendorInfo';
import Destination from './Destination';
import PreferenceIcons from './PreferenceIcons';
import RateDetailsButton from './RateDetailsButton';
import RateDetailsModal from './RateDetailsModal';
import RateTypeIcons from './RateTypeIcons';
import RequestLabel from './RequestLabel';
import { Trans } from 'react-i18next';

class PaymentInfo extends React.Component {
    static propTypes = {
        paymentOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    render() {
        const {paymentOptions} = this.props;

        return (
            <div>
                <div className="c-content__item__heading">{gettext('Accepted Payments')}:</div>
                {paymentOptions.map(getPaymentLabel).filter(identity).join(' / ')}
            </div>
        );
    }
}

export class RateTotal extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        totalAfterTaxes: PropTypes.number,
        ratePlan: PropTypes.object,
    };

    render () {
        const {total, currency, totalAfterTaxes, ratePlan} = this.props;

        const {amount, type} = getRatePlanI18N(ratePlan);

        return (
            <div className="c-content__item__price">
                <strong>{formatPrice(amount, currency)}</strong>
                {' '}/{' '}
                <span className="small">{type?.toLowerCase() || gettext('N/A')}</span>
                <div className="c-content__item__price__sub">
                    <strong>{formatPrice(totalAfterTaxes || total, currency)}</strong> / {gettext('total')}
                </div>
            </div>
        );
    }
}


export default class Rate extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        car: PropTypes.object.isRequired,
        collectionDelivery: PropTypes.object.isRequired,
        pickup: PropTypes.object.isRequired,
        dropoff: PropTypes.object.isRequired,
        ratePlan: PropTypes.object.isRequired,
        labeling: PropTypes.object.isRequired,
        preferredBy: PropTypes.arrayOf(PropTypes.string).isRequired,
        preferenceTiers: PropTypes.object.isRequired,
        paymentOptions: PropTypes.array.isRequired,
        outOfPolicyReasonCodes: PropTypes.array.isRequired,
        isBcdCdRate: PropTypes.bool.isRequired,
        outOfPolicy: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.bool
        ]),
        isIdRate: PropTypes.bool.isRequired,
        isItRate: PropTypes.bool.isRequired,
        isCdRate: PropTypes.bool.isRequired,
        isOnRequest: PropTypes.bool.isRequired,
        vendor: PropTypes.object.isRequired,
        rateId: PropTypes.number.isRequired,
        pictures: PropTypes.array,
        totalAfterTaxes: PropTypes.number,
    };

    getColDelMarkup(collectionDelivery) {
        const {collection, delivery, outOfHours} = collectionDelivery || {};

        if (collection && delivery) {
            return gettext('Delivery/Collection available');
        }

        if (collection) {
            return gettext('Collection available');
        }

        if (delivery) {
            return gettext('Delivery available');
        }

        return gettext('Delivery/Collection available out-of-hours');
    };

    handleCopyToClipboard = () => {
        const rate = this.props;
        copyCarOffer(rate);
    };

    renderTooltip(reasons) {
        const reasonText = {
            'car_size': gettext('Car type is out of policy'),
            'price_policy': gettext('Rate is over the price cap'),
            'preferred_only': gettext('Preferred vendors only can be booked'),
        };
        return (<Tooltip id="out_of_policy">
            {reasons.map((reason, index) => (
                <div key={index}>
                    <span>{reasonText[reason]}<br /></span>
                </div>))}
        </Tooltip>);
    }

    render() {
        const {
            total,
            currency,
            car,
            collectionDelivery,
            pickup,
            dropoff,
            ratePlan,
            paymentOptions,
            preferenceTiers,
            preferredBy,
            provider,
            isBcdCdRate,
            isIdRate,
            isItRate,
            isCdRate,
            isOnRequest,
            vendor,
            rateId,
            pictures,
            outOfPolicy,
            outOfPolicyReasonCodes,
            labeling,
            totalAfterTaxes = null
        } = this.props;
        const isPublicRate = !(isIdRate || isCdRate || isBcdCdRate || isItRate);
        const biggerPicture = pictures && maxBy(pictures,'size.width');
        const showOutOfPolicy = outOfPolicy && labeling['label_out_of_policy'];
        const btnStyle = !isOnRequest ? 'info' : 'warning';
        const {collection, delivery, outOfHours} = collectionDelivery || {};
        const colDelOhrIsPresent = collection || delivery || outOfHours;
        return (
            <div className="c-content__item">
                <div className="c-content__item__hover">
                    <CopyToClipboardTooltip onClick={this.handleCopyToClipboard} />
                </div>

                <div className="c-content__item__col  c-content__item__col--price">
                    {biggerPicture && <div className="c-content__item__img" style={{'backgroundImage': `url(${biggerPicture.url})`}}/>}
                    {!biggerPicture && <div className="c-content__item__no-img" />}
                    <RateTotal ratePlan={ratePlan} total={total} totalAfterTaxes={totalAfterTaxes} currency={currency}/>
                </div>

                <div className="c-content__item__col  c-content__item__col--info">
                    <CarInfo {...car} kind="search" className="c-content__item__car" />
                    <VendorInfo {...vendor} className="c-vendor" />
                    <RequestLabel isOnRequest={isOnRequest} />
                </div>
                <div className="c-content__item__col c-content__item__col--location">
                    <Destination {...pickup} extraCls="muted c-content__item__destination"/>
                </div>
                <div className="c-content__item__col c-content__item__col--location">
                    <Destination {...dropoff} extraCls="muted c-content__item__destination"/>
                </div>
                <div className="c-content__item__col c-content__item__col--control">
                    <BookButton rateId={rateId} bsStyle={btnStyle}>
                        {isOnRequest ? gettext('Request') : gettext('Book Now')}
                    </BookButton>
                    {showOutOfPolicy && <OverlayTrigger placement="right" overlay={this.renderTooltip(outOfPolicyReasonCodes)}>
                        <div className="c-content__item__label highlight-red">
                            {gettext('Out of Policy')}
                        </div></OverlayTrigger>}
                </div>
                <footer className="c-content__item__footer">
                    <div className="c-content__item__footer-col c-content__item__footer-col--common">
                        <PreferenceIcons labeling={labeling} preferenceTiers={preferenceTiers} preferredBy={preferredBy} />
                    </div>
                    <div className="c-content__item__footer-col c-content__item__footer-col--details">
                        <RateTypeIcons
                            labeling={labeling}
                            isItRate={isItRate}
                            isIdRate={isIdRate} isCdRate={isCdRate} isBcdCdRate={isBcdCdRate}
                            className="c-rate-ico__wrapper" />
                        <RateDetailsButton
                            rateId={rateId}
                            canBook
                            modal={<RateDetailsModal rateId={rateId} canBook />}
                        />
                    </div>
                    <div className="c-content__item__footer-col">
                        <MileageInfo {...ratePlan} layout="inline" />
                        {provider === 'amadeus' && colDelOhrIsPresent &&
                            <div className={
                                classnames('c-content__item__features',
                                    {
                                        'c-content__item__features__warning': outOfHours
                                            && !(collection || delivery)
                                    }
                                )}>
                                <span className={'glyphicon glyphicon-ok'}/>
                                {' '}
                                {this.getColDelMarkup(collectionDelivery)}
                            </div>
                        }
                        <div className={classnames('c-content__item__features', {'muted': isPublicRate})}>
                            <span className={classnames('glyphicon', {'glyphicon-remove': isPublicRate, 'glyphicon-ok': !isPublicRate})} />
                            {' '}
                            {isPublicRate
                                ? <Trans
                                    i18nKey="carRateCardNotEligible"
                                    components={{
                                        b: <strong/>,
                                    }}
                                />
                                : <Trans
                                    i18nKey="carRateCardEligible"
                                    components={{
                                        b: <strong/>,
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <div className="c-content__item__footer-col c-content__item__footer-col--payments">
                        <PaymentInfo paymentOptions={paymentOptions}/>
                    </div>
                </footer>
            </div>
        );
    }
}
